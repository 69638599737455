import React, { useState, type MouseEvent } from "react"
import clsx from "clsx"
import { Page } from "../components/layout"
import type { CardProps } from "../components/card/Card"
import { Card } from "../components/card/Card"
import "./index.scss"
import { UniversalLink } from "../components/UniversalLink"
import { BenefitsCard, BenefitsNotes } from "../components/home/Benefits"
import { ProgramCard } from "../components/home/Program"
import { Process } from "../components/home/Process"
import { Partner } from "../components/home/Partner"

const PrerequisiteCard: React.FC<CardProps & { description: string }> = ({
  description,
  trim,
}) => (
  <Card className="prereq-card" trim={trim}>
    <p>{description}</p>
  </Card>
)

const CohortPhases: React.FC<{
  title: string
  description: string
  borderColor: string
}> = ({ title, description, borderColor }) => (
  <div className="cohort-container row-span-1 md:col-span-1 mb-0 md:mb-4">
    <div
      className={clsx(
        `rounded overflow-hidden shadow-lg phases-card border-${borderColor}`
      )}
    >
      <div className="phases-card-inner">
        <div className="title-2 sm:title-1 phase-title">{title}</div>
        <div className="longform">{description}</div>
      </div>
    </div>
  </div>
)

interface Phase {
  title: string
  description: string
  borderColor: string
}
const CohortCard: React.FC<
  CardProps & {
    title: string
    date?: string
    location?: string
    format?: string
    openDate?: string
    closeDate?: string
    partnerURL?: string
    partnerName?: string
    phases?: Array<Phase>
    cohortOpen?: boolean
    divisionBar?: boolean
    isComing?: boolean
    outsidePartner?: boolean
    applicationLink?: string
  }
> = ({
  title,
  date,
  location,
  openDate,
  closeDate,
  format,
  partnerName,
  partnerURL,
  phases,
  cohortOpen,
  trim,
  divisionBar,
  isComing,
  outsidePartner,
  applicationLink,
}) => {
  const [open, setOpen] = useState(true)
  const [show, setShow] = useState(true)
  const toggleCard = () => {
    setOpen(!open)
  }
  return (
    <div
      className={clsx(
        `card-wrapper col-span-2 sm:col-span-4 md:col-span-6 md:col-start-2 lg:col-span-8 lg:col-start-3`
      )}
    >
      <Card className="cohort-card" trim={trim}>
        <div className="cohort-header flex">
          <div className="cohort-overview">
            <h5 className="cohort-card-titles">{title}</h5>
            {date && <p className="cohort-date">{date}</p>}
          </div>
          {!isComing && (
            <button className="cohort-show" onClick={() => setShow(!show)}>
              <span className="text-blue-purple-50 cohort-show-text flex">
                {show ? `Less` : `More`}
                <span
                  className={`text-blue-purple-50 cohort-show-button ${
                    show ? ` toggle-less` : ` toggle`
                  }`}
                />
              </span>
            </button>
          )}
          {isComing && (
            <span className="text-blue-purple-50 cohort-show">Coming soon</span>
          )}
        </div>
        {divisionBar && <div className="cohort-orange-bar" />}
        {show && (
          <div className="cohort-details">
            {location && (
              <div className="cohort-details-row">
                <p>
                  <strong>Program location:</strong> {location}
                </p>
              </div>
            )}
            {format && (
              <div className="cohort-details-row">
                <p>
                  <strong>Format:</strong> {format}
                </p>
              </div>
            )}
            {openDate && (
              <div className="cohort-details-row">
                <p>
                  <strong>Application opens:</strong> {openDate}
                </p>
              </div>
            )}
            {closeDate && (
              <div className="cohort-details-row">
                <p>
                  <strong>Application closes:</strong> {closeDate}
                </p>
              </div>
            )}
            {partnerName && (
              <div className="cohort-details-row">
                <strong> Program partner:</strong>

                <div className="cohort-partner-link">
                  <UniversalLink to={partnerURL}>
                    <img
                      className="cohort-partner-logo"
                      src={`images/logos/${partnerName}.svg`}
                      alt="ripple logo"
                    />
                  </UniversalLink>
                  <UniversalLink
                    to={partnerURL}
                    className="cohort-partner-link"
                  >
                    Learn About {partnerName}
                  </UniversalLink>
                </div>
              </div>
            )}
            {cohortOpen && (
              <div className="apply-now">
                <UniversalLink
                  className="btn btn-primary btn-fixed"
                  to={
                    applicationLink
                      ? applicationLink
                      : `https://portal.xrplaccelerator.org/en/register/as/Applicant`
                  }
                >
                  {outsidePartner ? (
                    <span>Learn&nbsp;More</span>
                  ) : (
                    <span>Apply&nbsp;Now</span>
                  )}
                  <span className="arrow">&#10132;</span>
                </UniversalLink>
              </div>
            )}
            {phases && (
              <div className="cohort-phases grid grid-cols-1 md:grid-cols-2">
                {phases.map((phase) => (
                  <CohortPhases
                    key={phase.title}
                    title={phase.title}
                    description={phase.description}
                    borderColor={phase.borderColor}
                  />
                ))}
              </div>
            )}
          </div>
        )}
      </Card>
    </div>
  )
}

export const scrollToCTA = (event: MouseEvent<HTMLButtonElement>) => {
  const cta = document.getElementsByClassName(`cta`)[0]
  cta.scrollIntoView({ behavior: `smooth` })
  const input = cta.getElementsByTagName(`input`)[0]
  input.focus()
}
const firstCohortPhasess = [
  {
    title: `Phase One - Exploration (4 weeks)`,
    description: `Teams focus on developing individual goals. Taking a broader, user-centric perspective, teams form hypotheses on entry barriers, market needs, and monetization.`,
    borderColor: `green`,
  },
  {
    title: `Phase Two - Validation (4 weeks)`,
    description: `Teams focus on building a refined, validated, attainable strategy blueprint. Hypotheses shaped during the first phase are translated into actionable business and product requirements.`,
    borderColor: `pink`,
  },
]
const launchItems = [
  `Empowering teams with foundational support for success in the XRPL ecosystem`,
  `Optional "primer" program preceding the XRPL Accelerator - Scale`,
  `Modules covering tech integration, product development, and business fundamentals`,
  `Access to expert mentors, funding opportunities, and a dedicated community`,
]
const scaleItems = [
  `Tailored for teams who have completed the precursor XRPL Launchpad programs`,
  `Includes a $100K grant (non-equity) with emphasis on securing further funding`,
  `Led by Funding Programs team, comprising mentorship and fundraising support`,
  `Culminates in an exclusive demo day for showcasing achievements and networking`,
]
export const Index = () => (
  <Page
    header={<>Accelerate Your&nbsp;Vision On the XRP Ledger</>}
    title="Software Developer Accelerator Program"
    keywords="crypto accelerator, ripple funding, blockchain accelerator, web3 accelerator, xrpl accelerator, ripple fund, developer funding, developer accelerator, open source funding, ripple grants, blockchain funding, web3 funding, software developer accelerator, xrp ledger funding, xrpl funding, xrpl awards, open source developer accelerator"
    description="XRPL Accelerator supports entrepreneurs building on the XRP Ledger. Through a tailored 360-degree analysis of their business model, the program helps develop a strategic blueprint for maximized growth potential."
    slug="home"
  >
    <div className="grid grid-cols-2 text-center sm:grid-cols-4 md:grid-cols-8 lg:grid-cols-12">
      <div className="text-center title-3 sm:title-1 col-span-2 sm:col-span-4 md:col-span-6 md:col-start-2 lg:col-span-8 lg:col-start-3">
        Explore opportunities for startups on the XRP Ledger with two
        initiatives: <b>XRPL Accelerator - Launch</b> and{` `}
        <b>XRPL Accelerator - Scale</b>, supporting developers and entrepreneurs
        at different stages.
      </div>
    </div>
    <div className="apply-and-contact-btn">
      <div className="apply-now">
        <UniversalLink
          className="btn btn-primary btn-fixed btn-alignment"
          to="https://submit.xrplgrants.org/submit"
        >
          <span>Apply&nbsp;Now</span>
          <span className="arrow">&#10132;</span>
        </UniversalLink>
      </div>
      <UniversalLink
        className=" btn btn-tertiary btn-fixed btn-alignment"
        to="mailto:info@xrplaccelerator.org"
      >
        Get In Touch
      </UniversalLink>
    </div>
    <div className="homepage-sections">
      <section className="our-different-programs">
        <div className="title">
          <h2>Our Different Programs</h2>
        </div>
        <div className="grid grid-cols-1 gap-y-[16px] sm:gap-y-[48px] sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 programs-grid">
          <ProgramCard
            title="XRPL Accelerator - Launch"
            description="A Catalyst for Start-Up Success on the XRP Ledger"
            trim="blue"
            message="Launch Program applications are currently open"
            descriptionItems={launchItems}
          />
          <ProgramCard
            title="XRPL Accelerator - Scale"
            description="Empowering Advanced Start-Ups to Scale on the XRP Ledger"
            trim="purple"
            message="Scale Program is not currently accepting applications"
            descriptionItems={scaleItems}
          />
        </div>
      </section>
      <section className="program-benefits">
        <div className="title">
          <h2>Program Benefits and Timeline</h2>
        </div>
        <div className="benefits-subsection xrpl-launchpad">
          <h6 className="benefits-subtitle">XRPL Accelerator - Launch</h6>
          <div className="grid grid-cols-1 gap-y-[16px] sm:gap-y-[48px] sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            <BenefitsCard
              title="Program funding"
              description={
                <div>
                  If completed as part of the{` `}
                  <UniversalLink
                    className="text-blue-purple-50 text-bold-card"
                    to="https://xrplgrants.org"
                  >
                    XRPL Grants
                  </UniversalLink>
                  {` `} program, offers a grant payout determined by the
                  team&rsquo;s product roadmap (grants range from $10K - $200K)
                </div>
              }
              trim="blue"
            />
            <BenefitsCard
              title="Mentorship"
              description={
                <div>
                  Unlocks access to mentorship opportunities spanning the XRPL
                  ecosystem and leveraging our partner {` `}
                  <UniversalLink
                    className="text-blue-purple-50 text-bold-card"
                    to="https://tenity.com"
                  >
                    Tenity&rsquo;s
                  </UniversalLink>
                  {` `} extensive network.
                </div>
              }
              trim="blue"
            />
            <BenefitsCard
              title="XRPL integration + business bootcamp"
              description="The Program consists of two segments: tech integration and business fundamentals. Participating projects have the flexibility to engage in either or both."
              trim="blue"
            />
            <BenefitsCard
              title="Network expansion"
              description="Join a dynamic community of innovators and entrepreneurs pioneering cutting-edge products at the forefront of technology on the XRP Ledger."
              trim="blue"
            />
          </div>
        </div>
        <div className="benefits-subsection flag-ship-accelerator">
          <h6 className="benefits-subtitle">XRPL Accelerator - Scale</h6>
          <div className="grid grid-cols-1 gap-y-[16px] sm:gap-y-[48px] sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            <BenefitsCard
              title="Program grant"
              description="Provides $100K grants (non-equity) with a strong emphasis on securing equity funding* post-graduation."
              trim="purple"
            />
            <BenefitsCard
              title="Industry-leading mentorship"
              description="Industry-leading mentorship, providing startups with guidance and support from experts in the ecosystem."
              trim="purple"
            />
            <BenefitsCard
              title="Tailored program curriculum"
              description="A tailored curriculum, designed to address the specific needs of each startup and provide comprehensive coverage of essential XRPL ecosystem topics."
              trim="purple"
            />
            <BenefitsCard
              title="Exposure & networking"
              description="We provide valuable exposure and networking opportunities, connecting startups with key players and fostering relationships within the web3 funding ecosystem."
              trim="purple"
            />
          </div>
        </div>
        <BenefitsNotes />
      </section>
      <section className="cohorts">
        <div className="title">
          <h2>2024 Cohorts</h2>
        </div>
        <div className="subtitle">
          <p className="note title-3 sm:title-2">
            Our 2025 cohorts are currently open. You can find out more and apply
            directly to them{` `}
            <UniversalLink
              className="text-blue-purple-50 text-bold"
              to="https://submit.xrplgrants.org/submit"
            >
              here
            </UniversalLink>
            .
          </p>
        </div>
        <CohortCard
          title="XRPL Accelerator - Scale"
          date="September 9 – November 15 2024"
          location="Hybrid (on-site elements will take place in Singapore and Dubai)"
          format="Remote & on-site demo week"
          openDate="July 22, 2024"
          closeDate="August 5, 2024"
          partnerURL="https://ripple.com/"
          partnerName="Ripple"
          cohortOpen={false}
          divisionBar={true}
          trim={false}
          phases={firstCohortPhasess}
          isComing={false}
        />
        <div className="cohort-notes">
          <div className="cohort-notes-title"> Please Note:</div>
          <div className="note">
            To get the most out of the program, apply for a cohort that best
            suits your business interests and team setup. Mentor availability
            and curriculum delivery align with the timezone of the program
            location. Part of the program content is tailored to the respective
            region.
          </div>
        </div>
      </section>

      <section className="who-to-apply">
        <div className="title">
          <h2>Who should apply</h2>
        </div>
        <div className="launch">
          <h6 className="subtitle"> XRPL Accelerator - Launch</h6>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-y-[48px]">
            <PrerequisiteCard
              description="Ambitious founding teams eager to explore the potential of the XRP Ledger in shaping innovative products."
              trim="blue"
            />

            <PrerequisiteCard
              description="Successful businesses wanting to join the XRP Ledger or integrated projects seeking business growth (or both!)"
              trim="blue"
            />

            <PrerequisiteCard
              description="While having an MVP is preferred, we welcome applications at various stages of development."
              trim="blue"
            />

            <PrerequisiteCard
              description="Alumni of the XRPL Grant Program are encouraged to apply, although prior grant funding is not a prerequisite for admission."
              trim="blue"
            />
          </div>
        </div>
        <div className="launch">
          <h6 className="subtitle"> XRPL Accelerator - Scale</h6>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-y-[48px]">
            <PrerequisiteCard
              description="Technically adept founding teams harnessing the power of the XRP Ledger."
              trim="purple"
            />

            <PrerequisiteCard
              description="You must have an MVP - the more traction you&rsquo;ve garnered, the stronger your application."
              trim="purple"
            />

            <PrerequisiteCard
              description="Your product should address a distinct pain point and possess significant market potential."
              trim="purple"
            />

            <PrerequisiteCard
              description="Applications are only open to start-ups that have completed an XRPL Accelerator Program.*"
              trim="purple"
            />
          </div>
        </div>
        <div className="title-3 sm:title-2">
          Have more questions? Check out our
          {` `}
          <UniversalLink className="apply-now text-blue-purple-50" to="/faq">
            FAQ
          </UniversalLink>
          {` `}
          or get in touch.
        </div>
        <p className="note preq-note">
          *If you believe your project meets our criteria, please get in touch
          with us at{` `}
          <UniversalLink
            to="mailto:info@xrplaccelerator.org"
            className="text-blue-purple-50 text-bold-card"
          >
            info@xrplaccelerator.org
          </UniversalLink>
        </p>
      </section>
      <section className="process-section">
        <div className="title">
          <h2>Process</h2>
        </div>
        <h6 className="subtitle">XRPL Accelerator - Launch</h6>
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5">
          <Process
            title="Open Applications"
            description="Applying is fast and easy. Share details about your product, traction, and team."
          />

          <Process
            title="Pre-screening"
            description="Reviews are on a rolling basis and are typically complete within 3 weeks."
          />

          <Process
            title="Finals"
            description="The most promising teams will be invited to a live call presenting to a panel of judges."
          />

          <Process
            title="Offers & Announcement"
            description="The strongest submissions will be selected and offers will be sent."
          />

          <Process
            title="Program Kick-off"
            description="Start the XRPL Accelerator journey and unlock new levels of growth."
          />
        </div>
        <div className="apply-link-div">
          <UniversalLink
            to="https://submit.xrplgrants.org/submit"
            className="apply-now text-blue-purple-50"
          >
            Apply for XRPL Accelerator - Launch here
          </UniversalLink>
        </div>

        <h6 className="subtitle">XRPL Accelerator - Scale</h6>
        <div>
          <p className="note">
            Our application process for the XRPL Accelerator - Scale Program is
            exclusive to startups that successfully completed an XRPL
            Accelerator - Launch Program. However, if you feel your project
            meets our criteria, please reach out to us at{` `}
            <UniversalLink
              to="mailto:info@xrplaccelerator.org"
              className="text-blue-purple-50 text-bold-card"
            >
              info@xrplaccelerator.org
            </UniversalLink>
            {` `}
            for further discussion.
          </p>
        </div>
      </section>
      <section className="partners">
        <div className="card-wrapper">
          <Card className="partners-card" trim={false}>
            <h5 className="subtitle">About the Supporting Partners</h5>
            <div className="purple-bar" />
            <div className="partners-wrapper">
              <Partner
                name="ripple"
                description="Through blockchain technology, Ripple enables global financial institutions, businesses, governments and developers to move, manage and tokenize value, helping to unlock greater economic opportunity for everyone, everywhere."
                website="https://ripple.com"
              />
              <Partner
                name="tenity"
                description="Tenity is a global innovation ecosystem and early-stage investor making innovation work and advancing the future of finance."
                website="https://www.tenity.com"
              />
              <Partner
                name="DIFC"
                description="DIFC Innovation Hub is the largest financial innovation ecosystem in the region – with more than 1,000 innovation and tech firms."
                website="https://www.difc.ae/ecosystem/innovation-hub"
              />
              <Partner
                name="FENASBAC"
                description="FENASBAC is a key player in Brazil&rsquo;s financial innovation landscape, offering start-up acceleration programs, a comprehensive event schedule, and consulting services to help shape the future of the financial system in permanent alignment with the regulatory framework."
                website="https://www.fenasbac.com.br"
              />
            </div>
          </Card>
        </div>
      </section>
    </div>
  </Page>
)
export default Index
